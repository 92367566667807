import * as R from 'ramda';
import './SignUp.less';
import React, { useState } from 'react';
import S from 'string';
import withForm from '@thecodeisgreen/withform';
import { Error, SelectRegion } from './Components';

import {
  Button,
  TextInput,
  FieldTitle,
  SelectButtons
} from '../../uikit/UIKit';

import SignUpMutation from '../../graphql/mutations/SignUpMutation';
import SignedUpPending from './components/SignedUpPending';
import SelectSales from './components/SelectSales';
import SelectCG from './components/SelectCG';
import SelectManager from './components/SelectManager';
import useKeyPressEnter from '../../hooks/useKeyPressEnter';
import withConfig from '../../withConfig';
import { validations } from '@fasstech/spid-front';
import withUser from '../../withUser';
import withSSO from '../../withSSO';

const roleOptions = [
  { title: 'Groupe adhésion', value: 'groupe_adhesion' },
  { title: 'Réseau commercial', value: 'reseau_commercial' },
  { title: 'Apporteur', value: 'apporteur' }
];

const structOptions = [
  { value: 'MDE' },
  { value: 'Mdpro' },
  { value: 'VAD' },
  { value: 'VADC' }
];

const fieldProps = {
  defaultValue: '',
  styleOnNotValid: { borderColor: 'orange' }
};

const SignUp = ({
  form,
  config,
  user = {},
  SSO_ENABLED
}) => {
  const [status, setStatus] = useState({ signedUp: false, error: false, message: undefined });

  const onResetError = () => {
    setStatus({ signedUp: false, error: false, message: undefined });
  };

  const onSignUp = () => {
    let {
      email,
      password,
      role,
      userCode,
      region,
      managementCenter,
      lastname,
      firstname,
      sales,
      commercialManagerId,
      providerEmail
    } = form.values();

    setStatus({ error: false, signedUp: false, message: undefined });

    SignUpMutation({
      email,
      password,
      role,
      userCode: S(userCode).toInt(),
      region,
      commercialManagerId,
      managementCenter,
      lastname,
      firstname,
      sales,
      providerEmail,
    }, (ok, error) => {
      if (error) {
        return setStatus({ error: true, signedUp: false, message: error });
      }

      return setStatus({ error: false, signedUp: true, message: undefined });
    });
  };

  const buttonDisabled = !form.isValid();
  useKeyPressEnter(onSignUp, !buttonDisabled);

  const roleIs = role => R.equals(role, form.values().role);
  const salesIs = sales => R.both(
    R.propEq('sales', sales),
    R.propEq('role', 'reseau_commercial')
  )(form.values());

  const getDefaultValue = prop => SSO_ENABLED ? R.pathOr('', R.split('.', prop), user) : '';

  if (status.signedUp) {
    return <div className="connection-box">
      <FieldTitle text="Créez votre compte"/>
      <SignedUpPending/>
    </div>;
  }

  return <div className="connection-box">
    <FieldTitle text="Première connexion : Créez votre compte"/>

    {form.manageField('email', {
      isValid: v => validations.emailIsValid(v),
      isUpdated: onResetError,
      ...fieldProps,
      defaultValue: getDefaultValue('email')
    })(
      <TextInput
        type="email"
        disabled={SSO_ENABLED}
        title="Identifiant"
        placeholder="Votre email"
      />
    )}

    {!SSO_ENABLED && <>
      {form.manageField('password', {
        isValid: (v, { lastname, firstname, email }) => {
          return validations.passwordIsValid(v, [lastname, firstname, email]);
        },
        isUpdated: onResetError,
        ...fieldProps
      })(
        <TextInput
          type="password"
          title="Mot de passe"
          placeholder="Votre mot de passe"
        />
      )}
      <div className="password-hint">{'Votre mot de passe doit contenir au moins 8 caractères (dont au moins un de type lettre, de type chiffre, de type symbole). Il ne peut pas contenir votre prénom, nom ou adresse mail.'}</div>

    </>}

    <div style={{ marginTop: '33px' }}>
      {form.manageField('role', { defaultValue: 'reseau_commercial' })(
        <SelectButtons
          options={R.propEq('disableProviders', true, config) ? R.reject(R.propEq('value', 'apporteur'), roleOptions) : roleOptions}
        />
      )}
    </div>

    <div style={{ marginTop: '33px', display: !roleIs('apporteur') && !salesIs('VADC') ? 'block' : 'none' }}>
      {
        form.manageField('userCode', {
          isValid: v => !roleIs('reseau_commercial') || salesIs('VADC') || (!R.isNil(v) && !R.isEmpty(v)),
          isUpdated: onResetError
        })(
          <TextInput
            type="text"
            title="Code conseiller"
            placeholder="23569"
          />
        )
      }
    </div>

    <div style={{ marginTop: '33px', display: !roleIs('apporteur') ? 'block' : 'none' }}>
      {
        form.manageField('region', {
          isValid: v => roleIs('apporteur') || !R.isNil(v),
          isUpdated: () => {
            form.values().manager = null;
            onResetError();
          }
        })(
          <SelectRegion
            title="Région"
          />
        )
      }
    </div>

    <div style={{ marginTop: '33px', display: roleIs('reseau_commercial') && !R.isNil(form.values().region) ? 'flex' : 'none' }}>
      {
        form.manageField('commercialManagerId', {
          isValid: v => roleIs('reseau_commercial') ? !R.isNil(v) && !R.isEmpty(v) : true,
          isUpdated: () => onResetError
        })(
          <SelectManager
            title="Manager"
            region={form.values().region}
          />
        )
      }
    </div>

    <div style={{ marginTop: '33px', display: roleIs('groupe_adhesion') ? 'flex' : 'none' }}>
      {
        form.manageField('managementCenter', {
          isValid: v => roleIs('groupe_adhesion') ? !R.isNil(v) && !R.isEmpty(v) : true,
          isUpdated: () => onResetError
        })(
          <SelectCG title="Centre de Gestion"/>
        )
      }
    </div>

    <div style={{ marginTop: '33px' }}>
      {
        form.manageField('lastname', {
          isValid: v => !R.isNil(v) && !R.isEmpty(v),
          isUpdated: () => onResetError,
          defaultValue: getDefaultValue('extra.lastname')
        })(
          <TextInput
            title="Nom"
            type="text"
            placeholder="Votre nom"
          />
        )
      }
    </div>

    <div style={{ marginTop: '33px' }}>
      {
        form.manageField('firstname', {
          isValid: v => !R.isNil(v) && !R.isEmpty(v),
          isUpdated: () => onResetError,
          defaultValue: getDefaultValue('extra.firstname')
        })(
          <TextInput
            title="Prénom"
            type="text"
            placeholder="Votre prénom"
          />
        )
      }
    </div>

    <div style={{ marginTop: '33px', display: roleIs('reseau_commercial') ? 'flex' : 'none' }}>
      {
        form.manageField('sales', {
          isValid: v => roleIs('reseau_commercial') ? !R.isNil(v) && !R.isEmpty(v) : true,
          isUpdated: () => onResetError
        })(
          <SelectSales
            title="Structure commerciale"
            options={structOptions}
            region={form.values().region}
          />
        )
      }
    </div>

    <div style={{ marginTop: '33px', display: roleIs('apporteur') ? 'block' : 'none' }}>
      {form.manageField('providerEmail', {
        isValid: v => !roleIs('apporteur') || validations.emailIsValid(v),
        isUpdated: onResetError,
        ...fieldProps
      })(
        <TextInput
          type="email"
          title="Email apporteur"
          placeholder="Votre email apporteur"
        />
      )}
    </div>

    {status.error &&
        status.message === 'EMAIL NOT ALLOWED' ? <Error message={'L\'adresse mail n\'est pas conforme.'}/>
      :
      status.error && <Error/>
    }

    <Button
      disabled={buttonDisabled}
      style={{ marginTop: '50px', marginBottom: '10px' }}
      onClick={onSignUp}
    >
      {'Valider'}
    </Button>
  </div>;
};

export default withSSO(withUser(withConfig(withForm(SignUp))));
